import React from "react";
import styled from "styled-components";

const Container = styled.div`
   display: flex;
   align-items: center;
   justify-content: center;

   .innerContainer {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 100%;
      max-width: var(--screen-max-width);
      margin: var(--screen-margin);
      padding: calc(100px + 88px) 0;
   }

   .privacy-policy-ul {
      list-style-type: none;
   }

   .privacy-policy-ol li {
      font-size: 16px;
      line-height: 26px;
   }

   li p {
      margin-top: 10px;
   }

   .privacy-policy-ul > li {
      margin-top: 40px;
   }

   @media only screen and (max-width: 600px) {
      .innerContainer {
         padding: 100px 0;
      }
   }
`;

export default function PrivacyPolicyTemplate() {
   return (
      <Container>
         <div className="innerContainer">
            <h1 className="privacy-policy-h1">
               Privacy Policy
               <br />
               <p style={{ color: "var(--text-muted)" }}>
                  Last Updated On 06-Nov-2024
               </p>
            </h1>
            <br />
            <p className="privacy-policy-p">
               This Privacy Policy describes the policies of Caixa Mágica
               Software, R. Soeiro Pereira Gomes – Lot 1, Lisbon 1600-198
               Lisbon, Portugal, email: connect@nearshore.pt, phone:
               +351217921260, on the collection, use and disclosure of your
               information that we collect when you use our website (
               https://www.nearshore.pt ). (the “Service”). By accessing or
               using the Service, you are consenting to the collection, use, and
               disclosure of your information in accordance with this Privacy
               Policy. If you do not consent to the same, please do not access
               or use the Service.
            </p>

            <p className="privacy-policy-p">
               We may modify this Privacy Policy at any time without prior
               notice and will post the revised Privacy Policy on the Service.
               The revised Policy will be effective 180 days from when the
               revised Policy is posted in the Service, and your continued
               access or use of the Service after such time will constitute your
               acceptance of the revised Privacy Policy. We recommend that you
               periodically review this page.
            </p>

            <ul className="privacy-policy-ul">
               <li>
                  <h3 className="privacy-policy-h3">Information We Collect:</h3>
                  <p className="privacy-policy-p">
                     We will collect and process the following personal
                     information about you:
                  </p>
                  <ol className="privacy-policy-ol">
                     <li>Name</li>
                     <li>Email</li>
                     <li>Mobile</li>
                     <li>Company</li>
                  </ol>
               </li>

               <li>
                  <h3 className="privacy-policy-h3">
                     How We Use Your Information:
                  </h3>
                  <p className="privacy-policy-p">
                     We will use the information that we collect about you for
                     the following purposes:
                  </p>
                  <ol className="privacy-policy-ol">
                     <li>Marketing/Promotional</li>
                     <li>Customer feedback collection</li>
                     <li>Targeted advertising</li>
                     <li>Manage customer orders</li>
                  </ol>
                  <p className="privacy-policy-p">
                     If we want to use your information for any other purpose,
                     we will ask for your consent and will use your information
                     only upon receiving your consent, and only for the
                     purpose(s) for which consent was granted unless required
                     otherwise by law.
                  </p>
               </li>

               <li>
                  <h3 className="privacy-policy-h3">
                     How We Share Your Information:
                  </h3>
                  <p className="privacy-policy-p">
                     We will not transfer your personal information to any third
                     party without seeking your consent, except in limited
                     circumstances as described below:
                  </p>
                  <ol className="privacy-policy-ol">
                     <li>Marketing agencies</li>
                     <li>Analytics</li>
                     <li>Data collection & processing</li>
                  </ol>
                  <p className="privacy-policy-p">
                     We require third parties to use the personal information we
                     transfer to them only for the purpose for which it was
                     transferred and not to retain it for longer than is
                     required for fulfilling the said purpose.
                  </p>
                  <p className="privacy-policy-p">
                     We may also disclose your personal information for the
                     following: (1) to comply with applicable law, regulation,
                     court order, or other legal process; (2) to enforce your
                     agreements with us, including this Privacy Policy; or (3)
                     to respond to claims that your use of the Service violates
                     any third-party rights. If the Service or our company is
                     merged or acquired by another company, your information
                     will be one of the assets that is transferred to the new
                     owner.
                  </p>
               </li>

               <li>
                  <h3 className="privacy-policy-h3">
                     Retention Of Your Information:
                  </h3>
                  <p className="privacy-policy-p">
                     We will retain your personal information as long as
                     necessary to fulfill the purposes for which it was
                     collected as detailed in this Privacy Policy. This
                     information may be retained for longer periods for purposes
                     such as record-keeping, fraud prevention, or legal
                     compliance. Residual anonymous and aggregate information,
                     which does not directly identify you, may be stored
                     indefinitely.
                  </p>
               </li>

               <li>
                  <h3 className="privacy-policy-h3">Your Rights:</h3>
                  <p className="privacy-policy-p">
                     Depending on the applicable law, you may have rights to
                     access, rectify, or erase your personal data. You may also
                     have the right to withdraw consent or request data
                     portability. To exercise your rights, write to us at
                     connect@nearshore.pt.
                  </p>
               </li>

               <li>
                  <h3 className="privacy-policy-h3">Security:</h3>
                  <p className="privacy-policy-p">
                     We use reasonable security measures to prevent unauthorized
                     access, alteration, or misuse of your information. However,
                     no method of transmission over the Internet or method of
                     electronic storage is 100% secure.
                  </p>
               </li>

               <li>
                  <h3 className="privacy-policy-h3">Third-Party Links:</h3>
                  <p className="privacy-policy-p">
                     Our Service may contain links to third-party websites. We
                     are not responsible for their privacy practices or content.
                     We encourage you to read the privacy policies of these
                     websites.
                  </p>
               </li>

               <li>
                  <h3 className="privacy-policy-h3">
                     Grievance/Data Protection Officer:
                  </h3>
                  <p className="privacy-policy-p">
                     For any concerns about the processing of your information,
                     contact our Data Protection Officer at
                     connect@nearshore.pt. We will address your concerns in
                     compliance with applicable laws.
                  </p>
               </li>
            </ul>
         </div>
      </Container>
   );
}
