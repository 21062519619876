import "../components/font-awesome";
import * as React from "react";
import Layout from "../components/layout";
import Seo from "../components/seo";
import styled from "styled-components";
import Footer from "../components/homePage/footer";
import PrivacyPolicyTemplate from "../templates/privacyPolicy";

const SeoWrapper = styled.div``;

const PrivacyPolicyPage = () => {
   return (
      <SeoWrapper>
         <Seo title={"Privacy Policy"} />
         <Layout>
            <PrivacyPolicyTemplate />
            <Footer />
         </Layout>
      </SeoWrapper>
   );
};

export default PrivacyPolicyPage;
